.main-content {
  height: 100vh;
  background-color: #f8f8f8;
  background-image: url("/fitainer-bg.png");
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 30px;
}

.request-access-form {
  height: 100vh;
  background-color: #fff;
  box-shadow: -15px 0px 30px 5px rgba(0, 0, 0, 0.09);
  padding: 10px 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 140px;
}

.video-tour-link {
  font-weight: 700;
  text-align: right;
}
.video-link {
  font-weight: bold;
  border: none;
  background: none;
  color: #d01616 !important;
}

.header-bar {
  align-items: center;
}

.main-description {
  margin-top: 6%;
}

.main-desc-list li {
  list-style-type: square;
}

.form-box {
  border: black 3px solid;
  border-radius: 0;
  font-size: 0.8em;
  padding: 25px;
  color: #1b1b1b;
}

.btn-access {
  background-color: #d01616;
  border: 0;
  border-radius: 0;
  padding: 15px 30px;
}

h2 {
  color: #d01616;
  font-weight: 900;
}
